import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { HttpProviderService } from '../services/http-provider/http-provider.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import * as moment from "moment";
import lodash from "lodash";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  sidebarMenus = {
    default: true,
    chat: false,
    settings: false,
  };

  notifications: [];

  org_name = "";
  org_logo = "";
  user_first_name = "";
  public user_profile_photo = "";
  blinker = "";
  user_id = "";
  isCandidate = false;

  pageFlag;

  public isAdmin = false;
  public isCloudaeon = false;
  public isFirstTimeUser: boolean = true;

  urlComplete = {
    mainUrl: "",
    subUrl: "",
    childUrl: "",
  };

  clearData(notfi: any) {

  }

  constructor(
    private localstorage: LocalStorageService,
    private router: Router,
    private httpProvider: HttpProviderService,
    private socialAuthService: SocialAuthService,
  ) {

  }

  ngOnInit(): void {
   
    // setInterval(() => {
    //   // console.log("exceuting every 10sec");
    //   this.addLeavesEveryMonth();
    // },60000)
   
    var data = this.localstorage.cookie();
    this.pageFlag = data;
    if (data == null) {
      this.router.navigate(['/login']);
    }
    else if (data['isCandidate']) {
      this.router.navigate(['/hr/candidate/profile/' + data['user_id']]);
      
    }
    else if (data['first_login']) {
      this.router.navigate(['/user/changepassword']);
    }
    this.org_name = data['organization_name'];
    this.org_logo = data['organization_logo'];
    this.user_first_name = data['first_name'];
    this.user_profile_photo = data['user_profile_photo'];
    this.blinker = data['blinker'];
    this.user_id = data['user_id'];
    this.isAdmin = data['isAdmin'];
    this.isFirstTimeUser = data['first_login'];
    this.isCandidate = data['isCandidate'];

    interval(1 * 10 * 1000)
      .pipe(
      ).subscribe(data => {

      });

    $(document).on("click", "#sidebar-menu a", function (e) {
      e.stopImmediatePropagation();
      if ($(this).parent().hasClass("submenu")) {
        e.preventDefault();
      }
      if (!$(this).hasClass("subdrop")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("subdrop");
        $(this).next("ul").slideDown(350);
        $(this).addClass("subdrop");
      } else if ($(this).hasClass("subdrop")) {
        $(this).removeClass("subdrop");
        $(this).next("ul").slideUp(350);
      }
    });
  }


 addLeavesEveryMonth() {
    let date  = new Date();
    // let temp = 25;
    if(date.getDate() === 1) {
      // if(temp === 25) { 
      console.log("Today Date");
      this.httpProvider.get("leaves/accrual/leaves").subscribe(
        res => {
          console.log(res);
          let filtered_array = lodash.filter(res.result, 
            { 'date': moment(date).format('YYYY-MM-DD')}
        );
      //   let filtered_array = lodash.filter(res.result, 
      //     { 'date': moment(date).format('2022-01-25')}
      // );
        console.log("filtered array",filtered_array);
        if(filtered_array.length === 0) {
          console.log("Filtered Array when empty");
          let obj = {
                  todays_date:moment(date).format("YYYY-MM-DD")
                }
                this.httpProvider.post("leaves/accrual/leaves",obj).subscribe(
                  res => {
                     console.log("Post request called when date is not present");
                  }
                )
          this.getAddedLeavesList();
          
        }
          // res.result.forEach(element => {
          //   if(element.date != moment(date).format("YYYY-MM-DD")) {
              
          //     console.log("if Loop")
          //     let obj = {
          //       todays_date:moment(date).format("YYYY-MM-DD")
          //     }
          //     this.httpProvider.post("leaves/accrual/leaves",obj).subscribe(
          //       res => {
          //          console.log("Post request called when date is not present");
          //       }
          //     )
          //   }
          //    else if(element.date === moment(date).format("YYYY-MM-DD")){
          //     console.log("Repeated Time");
          //   }
          // });
          // if(res.result.length === 0) {
          //  let obj = {
          //   todays_date:moment(date).format("YYYY-MM-DD")
          // }
          // this.httpProvider.post("leaves/accrual/leaves",obj).subscribe(
          //   res => {
          //     console.log("When post request result is empty")
          //   }
          // )
          // } 
        
        }
      )
    }
  }


  getAddedLeavesList() {
    this.httpProvider.get("leaves/accrual/leaves").subscribe(
      res => {})
  }
 


  logout() {
    this.httpProvider.get("oauth/logout");
    this.localstorage.delete('data');
    this.socialAuthService.signOut();
    if (this.isCandidate) {
      this.router.navigate(['/login/candidate']);
    }
    else {
      this.router.navigate(['/login']);
    }
  }

}
