import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  store(key: string, data: JSON) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  fetch(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  cookie() {
    return JSON.parse(localStorage.getItem('data'));
  }

  delete(key) {
    localStorage.removeItem(key);
  }

}
