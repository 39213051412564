import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpProviderService } from '../services/http-provider/http-provider.service';

@Component({
  selector: 'app-test1',
  templateUrl: './test1.component.html',
  styleUrls: ['./test1.component.css']
})
export class Test1Component implements OnInit {

  public myForm: FormGroup;
  public isLoading: Boolean = false;

  public isresult: any = false;
  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    // this.router.navigate(['/login']);
    this.myForm = this.formBuilder.group({
      emails: new FormControl(''),
    });
  }
  baseUrl = location.origin + "/api/";
  post(url: string, body: any): Observable<any> {

    return this.http.post(this.baseUrl + url, body)
      .pipe(map(res => {
        return res;
      }), catchError((err, caught) => {
        return of(err.error);
      }
      )
      );
  }

  myFunc() {
    this.isLoading = true;
    // console.log("function called");
    console.log(this.myForm.value);
    this.post("email-validate", this.myForm.value
    ).subscribe(res => {
      if (res.status_code === 200) {
        this.isLoading = false;
        this.toastr.success("", "Success");
        this.isresult = res.result;
      }
      else {
        this.isLoading = false;
        this.isresult = false;
        this.toastr.error(res.message, "Error");
      }
    });


  }
}
