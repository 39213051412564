import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { catchError, map, tap } from "rxjs/operators";
import { Observable, Observer } from "rxjs";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpProviderService {
  result;
  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  
   
  fileUploadOnboardingBoolean = false;
  fileUploadingWithoutSession = false;
  fileUploadForReimb = false;
  baseUrl = location.origin + "/api/";
  // baseUrl = "http://dev.hrms.com:80/api/"; 
  // baseUrl = location.origin + "/api/";
  // baseUrl = "http://redbixbite.hrms.com/api/";
  // baseUrl = "http://3.6.111.125/api/"
  getHeaders() {
    var data = this.localstorage.cookie();
    if (data == null) {
      this.router.navigate(["/login"]);
    }
    let headers = new HttpHeaders();
    if(this.fileUploadOnboardingBoolean === true || this.fileUploadForReimb === true) {
      headers = headers.append("Authorization", data["session_id"]);
      headers = headers.append("fileUpload", "true");
    } else if(this.fileUploadingWithoutSession === true) {
      headers = headers.append("fileUpload", "true");
    } 
    else {
      headers = headers.append("Authorization", data["session_id"]);
    }
  
    return headers;
  }

  get(url: string): Observable<any> {
    return this.http
      .get(this.baseUrl + url, { headers: this.getHeaders() })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err, caught) => {
          if (err.status == 403) {
            this.localstorage.delete("data");
            this.router.navigate(["/login"]);
          }
          return of(err.error);
        })
      );
  }

  getWithoutToken(url: string): Observable<any> {
    return this.http
      .get(this.baseUrl + url)
     
        // catchError((err, caught) => {
        //   if (err.status == 403) {
        //     this.localstorage.delete("data");
        //     this.router.navigate(["/login"]);
        //   }
        //   return of(err.error);
        // })
      // );
  }

  post(url: string, body: any): Observable<any> {
    return this.http
      .post(this.baseUrl + url, body, { headers: this.getHeaders() })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err, caught) => {
          if (err.status == 403) {
            this.localstorage.delete("data");
            this.router.navigate(["/login"]);
          }
          return of(err.error);
        })
      );
  }

  postWithoutToken(url: string, body: any): Observable<any> {
    return this.http
      .post(this.baseUrl + url, body)
      // .pipe(
      //   map((res) => {
      //     return res;
      //   }),
      //   catchError((err, caught) => {
      //     if (err.status == 403) {
      //       this.localstorage.delete("data");
      //       this.router.navigate(["/login"]);
      //     }
      //     return of(err.error);
      //   })
      // );
  }

  put(url: string, body: any): Observable<any> {
    return this.http
      .put(this.baseUrl + url, body, { headers: this.getHeaders() })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err, caught) => {
          if (err.status == 403) {
            this.localstorage.delete("data");
            this.router.navigate(["/login"]);
          }
          return of(err.error);
        })
      );
  }

  putWithoutToken(url: string, body: any): Observable<any> {
    return this.http
      .put(this.baseUrl + url, body)
      // .pipe(
      //   map((res) => {
      //     return res;
      //   }),
      //   catchError((err, caught) => {
      //     if (err.status == 403) {
      //       this.localstorage.delete("data");
      //       this.router.navigate(["/login"]);
      //     }
      //     return of(err.error);
      //   })
      // );
  }

  delete(url: string, body: any): Observable<any> {
    return this.http.request("delete", this.baseUrl + url, {
      headers: this.getHeaders(),
      body: body,
    });
  }

  deleteWithName(url: string): Observable<any> {
    return this.http
      .delete(this.baseUrl + url, { headers: this.getHeaders() })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err, caught) => {
          if (err.status == 403) {
            this.localstorage.delete("data");
            this.router.navigate(["/login"]);
          }
          return of(err.error);
        })
      );
  }

  public downloadfile(url: string): Observable<any> {
    return this.http.get(this.baseUrl + url, {
      headers: this.getHeaders(),
      responseType: "arraybuffer",
    });
  }

  public downloadfileUsingPost(url: string, body): Observable<any> {
    return this.http.post(this.baseUrl + url, body, {
      headers: this.getHeaders(),
      responseType: "arraybuffer",
    });
  }

  
  public upload_file(url: string,formData): Observable<any> {
    var header = this.getHeaders()
    header.append('Content-Type', 'multipart/form-data');
    header.append('Accept', 'application/json');
    return this.http.post(this.baseUrl + url, formData ,{
      headers: header,
    });
  }


  public upload_fileOnboard(url: string,formData): Observable<any> {
    // var data = this.localstorage.cookie();
    // if (data == null) {
    //   this.router.navigate(["/login"]);
    // }
    // // var data = this.localstorage.cookie();
    // let header = new HttpHeaders();
    this.fileUploadOnboardingBoolean = true;
    var header = this.getHeaders();
    // header.append("Authorization", data["session_id"])
    // header.append('fileupload', 'true')
    // header.append('Content-Type', 'multipart/form-data');
    console.log(header);
    // header.append('Accept', 'application/json');
   
    return this.http.post(this.baseUrl + url, formData ,{
      headers: header,
    });
  }

  public upload_fileCandidate(url: string,formData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("fileUpload", "true");
    console.log(headers);
    return this.http.post(this.baseUrl + url, formData ,{
      headers: headers,
    });
  }

  public upload_fileCandidateWithoutToken(url: string,formData) {
    let headers = new HttpHeaders();
    headers = headers.append("fileUpload", "true");
    console.log(headers);
    return this.http.post(this.baseUrl + url, formData ,{
      headers: headers,
    });
  }

  public upload_fileReimb(url: string,formData): Observable<any> {
    this.fileUploadForReimb = true;
    let headers = this.getHeaders();
    console.log(headers);
    return this.http.post(this.baseUrl + url, formData ,{
      headers: headers,
    });
  }
}
