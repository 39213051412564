import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { interval } from "rxjs";
import { HttpProviderService } from "src/app/services/http-provider/http-provider.service";
import { LocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { HeaderService } from "./header.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  jsonData: any = {
    notification: [],
    message: [],
  };
  notifications: [];

  org_name = "";
  org_logo = "";
  user_first_name = "";
  user_profile_photo = "";
  blinker = "";

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private localstorage: LocalStorageService,
    private httpProvider: HttpProviderService,
  ) { }

  ngOnInit() {
    var data = this.localstorage.cookie();
    this.org_name = data['organization_name'];
    this.org_logo = data['organization_logo'];
    this.user_first_name = data['first_name'];
    this.user_profile_photo = data['user_profile_photo'];
    this.blinker = data['blinker'];

    interval(1 * 10 * 1000)
      .pipe(
      ).subscribe(data => {
        console.log("SSSSS")

      });


    // this.notifications = [
    //   {
    //     message: "Patient appointment booking",
    //     author: "John Doe",
    //     function: "added new task",
    //     time: "4 mins ago",
    //   },
    //   {
    //     message: "Patient appointment booking",
    //     author: "John Doe",
    //     function: "added new task",
    //     time: "1 hour ago",
    //   },
    //   {
    //     message: "Patient appointment booking",
    //     author: "John Doe",
    //     function: "added new task",
    //     time: "4 mins ago",
    //   },
    //   {
    //     message: "Patient appointment booking",
    //     author: "John Doe",
    //     function: "added new task",
    //     time: "1 hour ago",
    //   },
    //   {
    //     message: "Patient appointment booking",
    //     author: "John Doe",
    //     function: "added new task",
    //     time: "4 mins ago",
    //   },
    //   {
    //     message: "Patient appointment booking",
    //     author: "John Doe",
    //     function: "added new task",
    //     time: "1 hour ago",
    //   },
    // ];


  }

  getDatas(section) {
    this.headerService.getDataFromJson(section).subscribe((data) => {
      this.jsonData[section] = data;
    });
  }

  clearData(section) {
    this.jsonData[section] = [];
  }
  onSubmit() {
    this.router.navigate(["/pages/search"]);
  }

  logout() {
    this.httpProvider.get("oauth/logout");
    this.localstorage.delete('data');
    this.router.navigate(['/login']);
  }
}
