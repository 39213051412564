import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseComponent } from "./base/base.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "misc",
    loadChildren: () =>
      import("./misc/misc.module").then(
        (m) => m.MiscModule
      ),
  },
  
  {
    path: "",
    component: BaseComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "organization",
        loadChildren: () =>
          import("./organization/organization.module").then(
            (m) => m.OrganizationModule
          ),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
      },
      {
        path: "configuration",
        loadChildren: () =>
          import("./configuration/configuration.module").then(
            (m) => m.ConfigurationModule
          ),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./reports/reports.module").then((m) => m.ReportsModule),
      },
      {
        path: "hr",
        loadChildren: () => import("./hr/hr.module").then((m) => m.HrModule),
      },
      {
        path: "performance",
        loadChildren: () => import("./performance/performance.module").then((m) => m.PerformanceModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
